<template>
  <!--begin::Wrapper-->
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="w-lg-650px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      @submit="onSubmit"
      :validation-schema="schema"
      @invalid-submit="onInvalidSubmit"
    >
      <div class="text-center pb-lg-15">
        <h3>Crea nuova password</h3>
        <h1 class="blueFit">Federazione Italiana Tennis e Padel</h1>
      </div>
      <div class="mb-5 bg-secondary py-7 px-4 rounded">
        <div class="blueFit fs-5 text-center">
          La tua password è stata resettata. Ora puoi crearne una nuova
        </div>
      </div>
      <div class="fv-row mb-10">
        <label class="form-label fs-6 fw-bolder text-dark"
          >Nuova password
        </label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          name="password"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="password" />
          </div>
        </div>
        <label class="form-label fs-6 pt-4 fw-bolder text-dark"
          >Conferma password
        </label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          name="confirm_password"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="confirm_password" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center d-flex">
        <button
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg bg-blueFIT text-white fx-bold fs-5 w-100 mb-5"
        >
          <span class="indicator-label"> Conferma password </span>
        </button>
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script>
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as yup from "yup";
import { newPassword } from "@/requests/oldLogin";
import { alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute, useRouter } from "vue-router";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import YupPassword from "yup-password";
YupPassword(yup);

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const isLoading = ref(false);

    const internal_guid = ref(route.query.g);

    const schema = yup.object().shape({
      password: yup
        .string()
        .required("Obbligatorio")
        .minLowercase(
          1,
          "La password deve contenere almeno una lettera minuscola"
        )
        .minUppercase(
          1,
          "La password deve contenere almeno una lettera maiuscola"
        )
        .minNumbers(1, "La password deve contenere almeno un numero")
        .minSymbols(
          1,
          "La password deve contenere almeno un carattere speciale"
        )
        .min(8, "La password deve avere un minimo di 8 caratteri"),

      confirm_password: yup
        .string()
        .oneOf([yup.ref("password"), null], "Le password devono coincidere"),
    });

    const onSubmit = (values) => {
      isLoading.value = true;
      newPassword(
        internal_guid.value,
        values.password,
        values.confirm_password
      ).then((res) => {
        isLoading.value = false;
        if (res.data.statusCode === 200) {
          Swal.fire({
            html: "Password creata con successo!",
            icon: "success",
            buttonsStyling: false,
            showCancelButton: false,
            confirmButtonText: "Chiudi",
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(async (e) => {
            if (e.isConfirmed) {
              router.push(`/sign-in`);
            }
          });
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    };

    return {
      schema,
      onSubmit,
      isLoading,
    };
  },
});
</script>

<style scoped>
:deep(.popper) {
  background: #e4e6ef !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #0d1660 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #e4e6ef !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #e4e6ef !important;
  left: 0px !important;
}

i {
  line-height: 1;
  font-size: 1rem;
  color: #0d1660;
}

.bg-blueFIT:focus {
  background: #0d165f !important;
}
</style>
